import { CustomerType } from "@origin-digital/platform-enums";
import {
  CommonParameters,
  IMcAppType,
  NavFlow,
  EXISTS_STRING,
  Tab,
} from "../mcApp.types";

const properties: NonNullable<IMcAppType["parameters"]>["properties"] = {
  caid: CommonParameters.caid,
  product: {
    description: "Allowed values are 'lpg'",
    type: "string",
  },
  customerNumber: CommonParameters.customerNumber,
  orderNumber: {
    description: "the order number for the last LPG order that was processed",
    type: "string",
  },
  hidePartialAmount: {
    description: "Hide partial amount or not",
    type: "boolean",
  },
  amount: {
    type: "string",
    description: "Account to pay in $, example '123.24'",
  },
};

const paymentMake_LPG: IMcAppType = {
  name: "paymentMake_LPG",
  path: "/my/make-lpg-payment",
  description: "Make a payment",
  parameters: {
    required: ["customerNumber"],
    properties,
  },
  navFlow: NavFlow.FOCUS,
  title: "Make a payment",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "paymentMake",
    customerType: CustomerType.kraken,
    parameters: {
      customerNumber: EXISTS_STRING,
    },
  },
};

const paymentMake_KRAKEN: IMcAppType = {
  name: "paymentMake_KRAKEN",
  path: "/my/make-payment/:accountNumber?",
  description: "Make a payment",
  parameters: {
    required: ["accountNumber"],
    properties: {
      accountNumber: CommonParameters.accountNumber,
    },
  },
  navFlow: NavFlow.FOCUS,
  title: "Make a payment",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "paymentMake",
    customerType: CustomerType.kraken,
  },
};

const paymentMake_UNAUTH: IMcAppType = {
  name: "paymentMake_UNAUTH",
  path: "/pay",
  description: "Unauth make a payment",
  parameters: { properties },
  navFlow: NavFlow.EXTERNAL_AUTH,
  title: "Make a payment",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "paymentMake",
    customerType: CustomerType.unauthenticated,
  },
};

export const paymentMake = [
  paymentMake_LPG,
  paymentMake_KRAKEN,
  paymentMake_UNAUTH,
];
