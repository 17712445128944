import { CustomerType } from "@origin-digital/platform-enums";
import {
  CommonParameters,
  IMcAppType,
  NavFlow,
  Tab,
  salesCommonParameters,
} from "../mcApp.types";

const { journeyType, planFetchStrategy, fuel, existingFuel } =
  salesCommonParameters;

// new sales form auth move for businessnavigation
export const salesFormAuthMoveBusiness: IMcAppType = {
  name: "salesFormAuthMoveBusiness",
  path: "/my/business/move-home",
  parameters: {
    properties: {
      journeyType,
      planFetchStrategy,
      fuel,
      existingFuel,
      originAddressId: CommonParameters.originAddressId,
    },
  },
  description: "Auth move sales form for business",
  navFlow: NavFlow.FOCUS,
  title: "Move home",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "salesFormAuthMoveBusiness",
    customerType: CustomerType.kraken,
  },
};
