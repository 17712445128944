import { CustomerType } from "@origin-digital/platform-enums";
import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

export const spikeV2Events: IMcAppType = {
  name: "spikeV2Event",
  path: "/my/service/spike-v2/events",
  parameters: {
    required: ["eventId"],
    properties: {
      eventId: {
        description: "Spike event ID",
        type: "string",
      },
    },
  },
  description: "Spike Event Opt In",
  navFlow: NavFlow.FOCUS,
  title: "Spike",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "spikeV2Event",
    customerType: CustomerType.kraken,
  },
};
