import React, { useState } from "react";
import { type IProperty } from "@origin-digital/user-context-provider/src/UserContext/UserContextService";
import {
  Box,
  Button,
  Card,
  Heading,
  Radio,
  RadioGroup,
  Sheet,
} from "@origin-digital/ods-core";
import { PropertySheetBottomLinks } from "./PropertySheetBottomLinks";

interface PropertySheetProps {
  selectedProperty: IProperty | undefined;
  setProperty: (property: IProperty | undefined) => void;
  properties: (IProperty | undefined)[];
  inactiveProperties: IProperty[];
  open?: boolean;
  setOpen: (show: boolean) => void;
}

const PropertyCard = ({ property }: { property?: IProperty }) => {
  const label = property
    ? `${property.address.houseNumber} ${property.address.street}, ${property.address.suburb} ${property.address.state} ${property.address.postcode}`
    : "All Active Properties";
  const id = property?.id ?? "all-properties";
  return (
    <Card
      id={`${id}-box`}
      outline={true}
      paddingYSpecific="xsmall"
      paddingXSpecific="small"
      // @ts-ignore // marginBottom Card prop works but isn't defined in ts signature
      marginBottom="small"
      key={id}
    >
      <Radio label={label} id={id} value={property?.id} />
    </Card>
  );
};

const mapPropertyCards = (p?: IProperty) => <PropertyCard property={p} />;

export const PropertySheet: React.FC<PropertySheetProps> = ({
  selectedProperty,
  properties,
  inactiveProperties,
  setProperty,
  open = false,
  setOpen,
}) => {
  const [showInactive, setShowInactive] = useState(false);
  const allProperties = inactiveProperties
    ? [...properties, ...inactiveProperties]
    : properties;
  return (
    <Sheet
      id="property-sheet"
      title="Properties"
      data-id="property-sheet"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      dialogMaxWidth="xs"
    >
      <Box display="flex" width="full" flexDirection="column">
        <RadioGroup
          name="Properties"
          value={selectedProperty?.id}
          onChange={(e) => {
            setProperty(allProperties.find((p) => p?.id === e.target.value));
            setOpen(false);
          }}
        >
          {properties.map(mapPropertyCards)}
          {inactiveProperties.length > 0 && !showInactive && (
            <Button
              noTextPadding
              variant="text"
              size="small"
              onClick={() => setShowInactive(true)}
            >
              Show previous properties
            </Button>
          )}
          {showInactive && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              marginY="small"
            >
              <Heading variant="h4">Previous Properties</Heading>
              <Button
                noTextPadding
                variant="text"
                size="small"
                onClick={() => setShowInactive(false)}
              >
                Hide
              </Button>
            </Box>
          )}
          {showInactive && inactiveProperties?.map(mapPropertyCards)}
        </RadioGroup>
      </Box>
      <PropertySheetBottomLinks closeSheet={() => setOpen(false)} />
    </Sheet>
  );
};
