import React from "react";

import styled from "styled-components";
import { Hidden } from "@origin-digital/ods-core";
import { ButtonLink } from "../Buttons/ButtonLinks";

const JoinUsWrapper = styled.div`
  display: none;
  margin-right: 8px;

  & a {
    display: block;
  }
`;

export const JoinUs = () => {
  return (
    <JoinUsWrapper data-id="navigation:join-origin-wrapper">
      <Hidden above="md">
        <ButtonLink
          href="/electricity-gas/plans.html"
          data-id="navigation:join-origin"
          trackingType="Link"
          trackingLabel="join-origin"
          color="#ec0000"
          bgColor="transparent"
          hoverColor="#ffeeee"
          borderColor="#ec0000"
        >
          Join
        </ButtonLink>
      </Hidden>
      <Hidden below="md">
        <ButtonLink
          href="/electricity-gas/plans.html"
          data-id="navigation:join-origin"
          trackingType="Link"
          trackingLabel="join-origin"
          color="#ec0000"
          bgColor="transparent"
          hoverColor="#ffeeee"
          borderColor="#ec0000"
        >
          Join Origin
        </ButtonLink>
      </Hidden>
    </JoinUsWrapper>
  );
};
