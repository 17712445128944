import React, { useState } from "react";
import { IconChevronDown, IconProperty } from "@origin-digital/ods-icons";
import { Box, Button, Inline, Text } from "@origin-digital/ods-core";
import {
  type IProperty,
  mockUserContext,
  useUserContext,
} from "@origin-digital/user-context-provider";
import { getAllPropertiesForUser } from "./utils";
import { PropertySheet } from "./PropertySheet";

const mapPropertyString = (p: IProperty) => {
  const { houseNumber, street, suburb } = p.address;
  return `${houseNumber} ${street}, ${suburb}`;
};

export const PropertySelector = () => {
  const { setProperty, property, user } = useUserContext();

  const [showSheet, setShowSheet] = useState<boolean>();

  // For testing only while API doesn't exist in staging. Can remove later and just use user
  const shouldMockUser = window.oetal?.config?.useMockAppContext;
  const [activeProperties, inactiveProperties] = getAllPropertiesForUser(
    shouldMockUser ? mockUserContext : user
  );

  if (activeProperties) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        width="full"
        justifyContent="center"
      >
        <Button
          aria-label="Property Selector Button"
          variant="text"
          onClick={() => setShowSheet(true)}
          color="secondaryB"
          size="small"
          data-id="property-selector"
        >
          <Inline alignY="center" space="small" disableWrap={true}>
            <IconProperty color="grey300" />
            <Text
              align="left"
              weight="medium"
              tone="neutral"
              truncate={true}
              inline={true}
            >
              {property ? mapPropertyString(property) : "All Properties"}
            </Text>
            <IconChevronDown size="small" color="neutral" />
          </Inline>
        </Button>
        <PropertySheet
          selectedProperty={property}
          setProperty={setProperty}
          properties={activeProperties}
          inactiveProperties={inactiveProperties}
          open={showSheet}
          setOpen={setShowSheet}
        />
      </Box>
    );
  }
  return <></>;
};
