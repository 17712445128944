import { CustomerType } from "@origin-digital/platform-enums";
import {
  IMcAppType,
  NavFlow,
  Tab,
  salesCommonParameters,
  CommonParameters,
} from "../mcApp.types";

const { journeyType, planFetchStrategy, fuel, existingFuel } =
  salesCommonParameters;
export const move_UNAUTH: IMcAppType = {
  name: "move_UNAUTH",
  path: "/moving/",
  parameters: {
    properties: {
      book: {
        type: "boolean",
        description: "Show 'Book my Move' button",
      },
    },
  },
  description: "Move house",
  navFlow: NavFlow.EXTERNAL_AUTH,
  title: "Move",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "move",
    customerType: CustomerType.unauthenticated,
  },
};

export const move_KRAKEN: IMcAppType = {
  name: "move_KRAKEN",
  path: "/my/move-home",
  parameters: {
    properties: {
      journeyType,
      planFetchStrategy,
      fuel,
      existingFuel,
      originAddressId: CommonParameters.originAddressId,
    },
  },
  description: "Auth move sales form",
  navFlow: NavFlow.FOCUS,
  title: "Move home",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410

  conditions: {
    intent: "move",
    customerType: CustomerType.kraken,
  },
};
