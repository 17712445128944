import { dapiFetchCache } from "@origin-digital/daxi";
import type {
  IProperty,
  IUserContext,
  IAddress,
  IAccount,
} from "@origin-digital/event-dispatcher";
import { mockUserContext } from "./mocks/builders";

export { IProperty, IUserContext as IUser, IAddress, IAccount };

const storage = (): Storage => window.localStorage;

const storageKey = "@od/selectedProperty";

export const readPropertyFromStorage = (): IProperty | null => {
  const json = storage().getItem(storageKey);
  if (json) {
    return JSON.parse(json); // Note this is not validated as a correctly shaped type.
  }
  return null;
};

export const writePropertyToStorage = (property: IProperty) => {
  try {
    storage().setItem(storageKey, JSON.stringify(property));
  } catch (error) {}
};

export const fetchUserContextV3 = async (): Promise<
  IUserContext | undefined
> => {
  /* This if statement is for debugging only, and can be removed once usercontext API is available in staging*/
  if (window.oetal?.config?.useMockAppContext) {
    return mockUserContext;
  }
  try {
    const res = await dapiFetchCache<IUserContext>("/api/appcontext/v3/user", {
      ttlInMin: 60,
      allowStaleData: true,
      authorization: "jwt",
    });
    return res.data;
  } catch {
    /* Return undefined if unable to fetch user context. Not currently used for critical functionality so can safely silently error
     Handles test cases where this is not configured, and avoids throwing errors for unauthenticated users */
    return undefined;
  }
};
