// eslint-disable babel/camelcase
import {
  Channel,
  CustomerType,
  NavFlow,
  Tab,
} from "@origin-digital/platform-enums";

export type Params = Record<string, Primitives | Primitives[]>;
export type Primitives = string | boolean | number | undefined;

// export for mc apps only
export { NavFlow, Tab };

// eslint-disable-next-line no-shadow
export enum Crm {
  kraken = "kraken",
}

export type McAppName = string;
export type McAppList = IMcAppType[];
export type McAppListMap = Record<McAppName, IMcAppType>;

export interface IMcAppType {
  /**
   * name, should be not use '_'
   * this will be the intent name if there are no conditions
   */
  name: McAppName;
  description: string;
  /**
   * matching routes will be serviced by the given mc apps,
   * ReactRouter / express notation - '/help-support/:category/:subCategory?`
   * ReactRouter / express notation - '/help-support/*'
   *
   * in addition this can be used to used to generate URLs, parameters will be appended to query string
   */
  path: string;
  /**
   * parameters the mc app accepts or requires,
   * these we be appended to the query string if they are not in the route definition
   *
   * @example: { path: "/help-support/:category", parameters: { category: "bills", context: "auth" } }
   * result url '/help-support/bills?context=auth'
   *
   */
  parameters?: {
    // phase 2 - if a required parameter is missing what do we do?
    // for example if caid is require we could show an fuel selector,
    // or redirect them to another URL
    /**
     * this of properties that are required when calling the mc app
     */
    required?: string[];
    /**
     * list of properties that can be used when calling this mc app
     */
    properties?: {
      [key: string]: BasicJSONSchema;
    };
  };
  navFlow: NavFlow;
  /**
   *  Sets the title of the action bar.
   */
  title: string;
  parent: Tab;
  /**
   * results are returned to the calling mc app either by NAV_COMPLETE native (Promise) or web (query string)
   */
  results?: {
    properties?: {
      [key: string]: BasicJSONSchema;
    };
  };
  conditions?: IConditions;
  /**
   * if true, it will warn others to no longer use this app
   */
  deprecated?: boolean;
  /**
   * if true, it will exclude it from being mapped by path
   */
  ignorePathMapping?: boolean;
}

export const EXISTS_STRING = "<string>";
export interface IConditions {
  /**
   * ways of group mc apps with the same intent,
   * if there are more than one app with the same intent only one will be shown
   */
  intent: string;
  /**
   * provided by the platform, checks the users jwt to get customerType
   */
  customerType?: CustomerType;
  /**
   * provided by the platform, checks the current channel
   */
  channel?: Channel;
  /**
   * jwt token has a limited scope
   */
  scopedToken?: boolean;
  /**
   * Custom parameters to be check, all must match
   * for existence of match use { key: "<string>" }
   */
  parameters?: Params;
}

export const salesCommonParameters: { [key: string]: BasicJSONSchema } = {
  journeyType: {
    description: "journeyType to be passed to the sales form",
    type: "string",
  },
  fuel: {
    description: "fuel to be passed to the sales form",
    type: "string",
  },
  existingFuel: {
    description: "existing fuel to be passed to the sales form",
    type: "string",
  },
  planFetchStrategy: {
    description: "planFetchStrategy to be passed to the sales form",
    type: "string",
  },
};

export const CommonParameters: { [key: string]: BasicJSONSchema } = {
  accountNumber: {
    description: "Kraken - Account Number",
    type: "string",
  },
  accountNumbers: {
    description: "List of Kraken - Account Numbers",
    type: ["string"],
  },
  agreementId: {
    description: "Kraken - Agreement ID",
    type: "string",
  },
  chargerId: {
    description: "EV Charger ID",
    type: "string",
  },
  customerNumber: {
    description: "LPG Salesforce - Customer Number (account number)",
    type: "string",
  },

  isUnauthenticated: {
    description: "If the user has an authentication token",
    type: "boolean",
  },
  isDigishift: {
    description:
      "If the user is using a scoped jwt token, navigating from digi shift (semi auth) path",
    type: "boolean",
  },

  originAddressId: {
    description:
      "Origin address id generated as part of the address resolution",
    type: "string",
  },

  referrerPath: {
    type: "string",
    description: `appends returnData from Promise to query string of referrerPath, if referrerPath is undefined it defaults to to window.location when navTo.fn() is called, if the referrerPath is set, then mesh will return the user back to this endpoint on NAV_CLOSE`,
  },
  midflow: {
    type: "boolean",
    description: `when it (App B) is being used as part of another flow (App A). example: App B doesn't show the thank you screen, since App A will when complete`,
  },
  cardId: {
    type: "string",
    description: `internal ID of credit card, example 000002`,
  },
  scheduleId: {
    description: "EV Charger Schedule ID",
    type: "string",
  },
  vehicleId: {
    description: "EV iCharge Vehicle ID",
    type: "string",
  },
  serviceType: {
    description: "electricity or natural-gas",
    type: "string",
  },
  surveyType: {
    description: "survey type",
    type: "string",
  },
  chargeLocationId: {
    description: "EV iCharge Charge Location ID",
    type: "string",
  },
  nmi: {
    description: "National Metering Identifier",
    type: "string",
  },
  vendor: {
    description: "EV manufacturers supported by EV iCharge",
    type: "string",
  },
  billId: {
    description: "Kraken - Bill ID",
    type: "string",
  },
};

export const HOME_INTENT = "home";
export const FALLBACK_INTENT = "fallback";
export const FALLBACK_MCAPP_NAME = "fallback_UNAUTH";

/* eslint-disable */
/// ------------- from JSON schema
export interface BasicJSONSchema {
  type: JSONSchema7TypeName | JSONSchema7TypeName[];
  /**
   * @see https://tools.ietf.org/html/draft-handrews-json-schema-validation-01#section-10
   */
  title?: string;
  description: string;
  default?: JSONSchema7Type;

  // validation
  /**
   * @see https://tools.ietf.org/html/draft-handrews-json-schema-validation-01#section-6.1
   */

  enum?: JSONSchema7Type[];
  const?: JSONSchema7Type;

  /**
   * @see https://tools.ietf.org/html/draft-handrews-json-schema-validation-01#section-6.2
   */
  multipleOf?: number;
  maximum?: number;
  exclusiveMaximum?: number;
  minimum?: number;
  exclusiveMinimum?: number;

  /**
   * @see https://tools.ietf.org/html/draft-handrews-json-schema-validation-01#section-6.3
   */
  maxLength?: number;
  minLength?: number;
  pattern?: string;

  /**
   * @see https://tools.ietf.org/html/draft-handrews-json-schema-validation-01#section-6.4
   */
  maxItems?: number;
  minItems?: number;
  uniqueItems?: boolean;
}

/**
 * Primitive type
 * @see https://tools.ietf.org/html/draft-handrews-json-schema-validation-01#section-6.1.1
 */
export type JSONSchema7TypeName =
  | "string"
  | "number"
  | "integer"
  | "boolean"
  | "object"
  | "array"
  | "null";

/**
 * Primitive type
 * @see https://tools.ietf.org/html/draft-handrews-json-schema-validation-01#section-6.1.1
 */
export type JSONSchema7Type =
  | string
  | number
  | boolean
  | JSONSchema7Object
  | JSONSchema7Array
  | null;

// Workaround for infinite type recursion
export interface JSONSchema7Object {
  [key: string]: JSONSchema7Type;
}

// Workaround for infinite type recursion
// https://github.com/Microsoft/TypeScript/issues/3496#issuecomment-128553540
export interface JSONSchema7Array extends Array<JSONSchema7Type> {}

/* eslint-enable */

export interface IBaseProps extends IMcAppType {
  subPath: string;
}

export interface IFullProps extends IBaseProps {
  intent: string;
  digishiftPath?: string;
  magiclinkPath?: string;
  customerType?: CustomerType;
}
