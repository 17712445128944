import { IService, type IUserContext } from "@origin-digital/event-dispatcher";
import { BackendCrm, ServiceType } from "@origin-digital/platform-enums";
import type {
  IAccount,
  IAddress,
  IProperty,
  IUser,
} from "../UserContextService";

export const buildAddress = (overrides?: Partial<IAddress>): IAddress => ({
  houseNumber: "123",
  id: "2",
  postcode: "0000",
  state: "Vic",
  street: "Fake St",
  suburb: "Nowhere",
  primary: true,
  ...overrides,
});
export const buildProperty = (overrides?: Partial<IProperty>): IProperty => ({
  address: buildAddress(),
  label: "Home",
  id: "1",
  ...overrides,
});

export const buildService = (overrides?: Partial<IService>): IService => ({
  id: "1",
  serviceType: ServiceType.electricity,
  serviceStatus: "ACTIVE",
  serviceAddress: buildAddress(),
  property: buildProperty(),
  endDate: new Date("2028-01-01"),
  ...overrides,
});

export const buildAccount = (overrides?: Partial<IAccount>): IAccount => ({
  id: "12",
  backendId: "123",
  addressId: "1",
  backendType: BackendCrm.KRAKEN,
  backendAccountId: "1",
  backendUserId: "1",
  services: [buildService()],
  ...overrides,
});

export const buildUser = (overrides?: Partial<IUser>): IUser => ({
  id: "1",
  accounts: [buildAccount()],
  firstName: "Joe",
  lastName: "Smith",
  features: [],
  ...overrides,
});

const address2 = buildAddress({
  street: "Pretend St",
  houseNumber: "4",
  suburb: "Melbourne",
  state: "Vic",
  postcode: "3000",
});
const address3 = buildAddress({
  street: "Old St",
  houseNumber: "100",
  suburb: "Sydney",
  state: "NSW",
  postcode: "2000",
});

export const mockUserContext: IUserContext = buildUser({
  accounts: [
    buildAccount(),
    buildAccount({
      services: [
        buildService({
          serviceAddress: address2,
          property: buildProperty({ id: "2", address: address2 }),
        }),
        buildService({
          serviceAddress: address3,
          serviceStatus: "CLOSED",
          property: buildProperty({ id: "3", address: address3 }),
        }),
      ],
    }),
  ],
});
