import React from "react";
import styled from "styled-components";
import { Nav, Link as LinkType } from "./nav-config";
import { SecondLevelNav } from "./SecondLevelNav";
import { NavLink } from "./NavLinks";
import { Theme, useMediaQuery } from "@mui/material";

export const gridAreaName = "firstLevel";
const NavBar = styled.div<{ showMenu: boolean }>`
  grid-area: ${gridAreaName};
  margin: auto 0px;
  display: grid;
  grid-auto-flow: column;
  width: fit-content;
  gap: 24px;

  position: relative;
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: -100vw;
    right: -100vw;

    transition: background 0.3s ease-in-out;
    background: ${(p) =>
      p.showMenu ? p.theme.colors.grey50 : p.theme.colors.white};

    ${(p) => p.theme.breakpoints.up("lg")} {
      background: ${(p) => p.theme.colors.grey50};
    }
  }

  @media (min-width: ${(p) => p.theme.breakpoints.lg}) {
    gap: 18px;
  }
  @media (min-width: ${(p) => p.theme.breakpoints.xl}) {
    gap: 30px;
  }
`;

type Props = {
  links: Nav["primaryLinks"];
  showMenu: boolean;
  onMobileTabChange: (index: number) => void;
};
export const PrimaryNav = ({ showMenu, links, onMobileTabChange }: Props) => {
  const isBelowLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  let secondLevelLinks: LinkType[] = [...links[0].children];
  if (isBelowLg) {
    // Add the parent link to the second level nav
    secondLevelLinks.unshift({
      title: links[0].title,
      link: links[0].link,
      selected: window.location.pathname === links[0].link,
    });
  }

  const primaryNavBar = (
    <NavBar showMenu={showMenu} role={isBelowLg ? "tablist" : undefined}>
      {links.map((link, idx) => {
        if (link.selected) {
          secondLevelLinks = [...link.children];
          if (isBelowLg) {
            // Add the parent link to the second level nav
            secondLevelLinks.unshift({
              title: link.title,
              link: link.link,
              selected: window.location.pathname === link.link,
            });
          }
        }
        return (
          <NavLink
            key={link.title}
            tabable={showMenu}
            link={link}
            onMobileClick={() => onMobileTabChange(idx)}
          />
        );
      })}
    </NavBar>
  );

  return (
    <>
      {primaryNavBar}
      <SecondLevelNav
        links={secondLevelLinks}
        showMenu={showMenu}
        role={isBelowLg ? "tabpanel" : undefined}
      />
    </>
  );
};
