import React from "react";
import styled from "styled-components";

import { Link as LinkType } from "./nav-config";
import { NavLink } from "./NavLinks";

export const gridAreaName = "secondLevel";
const NavBar = styled.div<{ $showMenu: boolean }>`
  padding-top: ${(p) => (p.role === "tabpanel" ? "20px" : "0")};
  align-content: start;
  grid-area: ${gridAreaName};
  overflow-y: ${(p) => (p.$showMenu ? "auto" : "hidden")};
  height: 100%;
  margin: auto 0px;
  display: grid;

  width: 100%;
  gap: 8px;
  ${(p) => p.theme.breakpoints.up("lg")} {
    padding-top: 0;
    width: fit-content;
    grid-auto-flow: column;
    gap: 24px;
  }
  ${(p) => p.theme.breakpoints.up("xl")} {
    gap: 36px;
  }
`;

const StyledNavLink = styled(NavLink)<{ link: LinkType }>`
  /* better movement in nav items when switching between tabs on mobile */
  view-transition-name: tal-nav-${(p) => p.link.title.replaceAll(" ", "-")};
`;

type Props = {
  links: LinkType[];
  showMenu: boolean;
  role?: React.AriaRole;
};

export const SecondLevelNav = ({ links, showMenu, role }: Props) => {
  return (
    <NavBar role={role} tabIndex={showMenu ? 0 : -1} $showMenu={showMenu}>
      {links.map((link) => (
        <StyledNavLink
          key={link.link}
          link={link}
          tabable={showMenu}
          largeOnMobile
        />
      ))}
    </NavBar>
  );
};
