import { CustomerType } from "@origin-digital/platform-enums";
import { IMcAppType, NavFlow, Tab } from "../mcApp.types";

const feedbackCommon: Omit<IMcAppType, "name" | "path"> = {
  description: "Loads the form stack feedback form for the given formCode",
  parameters: {
    required: ["formCode"],
    properties: {
      context: {
        description: "Additional custom customer context",
        type: "string",
      },
      formCode: {
        description: "ID of the form",
        type: "string",
      },
      title: {
        description: "Title for the ActionBar once the form has loaded",
        type: "string",
        default: "Feedback",
      },
    },
  },
  navFlow: NavFlow.MODAL,
  title: "Feedback",
  parent: Tab.DASHBOARD, // Changed from Tab.MORE, Temporary workaround due to navigation issues on Mobile. See https://origindd.atlassian.net/browse/P21-2410
};

export const feedback_KRAKEN: IMcAppType = {
  ...feedbackCommon,
  name: "feedback_KRAKEN",
  path: "/my/feedback/",
  conditions: {
    intent: "feedback",
    customerType: CustomerType.kraken,
  },
};

export const feedback_UNAUTH: IMcAppType = {
  ...feedbackCommon,
  name: "feedback_UNAUTH",
  path: "/feedback/",
  navFlow: NavFlow.EXTERNAL,
  conditions: {
    intent: "feedback",
    customerType: CustomerType.unauthenticated,
  },
};
