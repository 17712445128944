import * as React from "react";
import styled, { createGlobalStyle } from "styled-components";

import { Link } from "./nav-config";
import { NavLink } from "./NavLinks";

export const gridAreaName = "secondary";
const viewTransitionName = "tal-unauth-secondary-nav";

const ViewTransitionStyles = createGlobalStyle`
  html::view-transition-group(${viewTransitionName}) {
    /* make sure that during transition the bottom fixed nav stays on top of any overflowing animations */
  	z-index: 100;
  }
`;
const AccountNavContainer = styled.div`
  view-transition-name: ${viewTransitionName};
  background-color: white;

  grid-area: ${gridAreaName};
  /* flex gap still not supported by some of our supported browsers (I think it's IOS).
  We can use grid gap as a drop in replacement instead */
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto auto 1fr;
  gap: 40px;
  height: 60px;

  border-top: 1px solid ${(p) => p.theme.colors.grey200};
  ${(p) => p.theme.breakpoints.up("lg")} {
    background-color: unset;
    border-top: none;
  }

  & > * {
    margin: auto 0 auto auto;
  }
`;

type Props = {
  links: Link[];
  showMenu: boolean;
};
export const SecondaryNav = ({ links, showMenu }: Props) => {
  return (
    <>
      <ViewTransitionStyles />
      <AccountNavContainer>
        {links.map((link) => (
          <NavLink key={link.title} link={link} tabable={showMenu} />
        ))}
      </AccountNavContainer>
    </>
  );
};
